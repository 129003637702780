var numeral = require('numeral');
import moment from 'moment'
import { EventBus } from './event-bus'
import User from '@/store/Models/User'
var slugifyPackage = require('slugify')
import QuilityAPI from '@/store/API/QuilityAPI.js';
import { UNLICENSED_AGENT_CODE, TEAM_LEADER_AGENT_CODE, SFG_DIRECT_AGENT_CODE } from '@/constants';

import { weekdiff } from '@/components/utils/BusinessTimeDateDiff.js'

export default {
    data: function() {
        return {}
    },
    mounted: function() {

    },
    methods: {
        slugify(str) {
            return slugifyPackage(str, '_');
        },
        formatCurrency(n) {
            return '$' + numeral(n).format('0,0');
        },
        formatShortCurrency(n) {
            return numeral(n).format('($0[.]0 a)');
        },
        formatLongCurrency(n) {
            return numeral(n).format('($0,0.00)');
        },
        formatPercentage(n) {
            return numeral(n * 100).format('0,0.0') + '%';
        },
        formatNumber(n, f) {
            return numeral(n).format(f);
        },
        formatPhoneNumber: function(phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
            return ""
        },
        hasRole(r) {
            //r should be an array if not conver the string to an array
            var rs = r
            if (typeof r == "string") {
                rs = [r]
            }
            for (var i = 0; i < rs.length; i++) {
                if (this.$auth.auth_user["http://quility.com/roles"].indexOf(rs[i]) > -1) {
                    return true;
                }
            }
            return false;
        },
        notRole(r) {
            //r should be an array if not conver the string to an array
            var rs = r
            if (typeof r == "string") {
                rs = [r]
            }
            for (var i = 0; i < rs.length; i++) {
                if (this.$auth.auth_user["http://quility.com/roles"].indexOf(rs[i]) > -1) {
                    return false;
                }
            }
            return true;
        },
        hasPermission(r) {
            if (this.hasRole('SuperAdmin')) {
                return true;
            }
            //r should be an array if not conver the string to an array
            var rs = r
            if (typeof r == "string") {
                rs = [r]
            }
            for (var i = 0; i < rs.length; i++) {
                if (this.user.permissions.indexOf(rs[i]) > -1) {
                    return true;
                }
            }
            return false;
        },
        hasDivision(r) {
            //r should be an array if not conver the string to an array
            var rs = r
            if (typeof r == "string") {
                rs = [r]
            }
            for (var i = 0; i < rs.length; i++) {
                if (this.divisions.indexOf(rs[i]) > -1) {
                    return true;
                }
            }
            return false;
        },
        formatDate(d) {
            if (d == null) {
                return ''
            }
            return moment(d).format('YYYY-MM-DD');
        },
        formatReadableDate(d) {
            if (d == null) {
                return ''
            }
            return moment(d).format('MMM D, YYYY');
        },
        formatDateTime(d) {
            return moment(d).format('YYYY-MM-DD LT');
        },
        formatDateDash(d) {
            return moment(d).format('MM-DD-YYYY');
        },
        formatDateCalendar(d) {
            return moment(d).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'ddd, MMM Do'
            });;
        },
        formatDateCalendarWithYear(d) {
            return moment(d).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'ddd, MMM Do, YYYY'
            });;
        },
        dateDaysSince(d) {
            return moment().diff(d, 'days')
        },
        monthFromDate(d) {
            return moment(d).format('MMMM');
        },
        formatMonthDay(d) {
            return moment(d).format('MM/D');
        },
        monthSimpleDate(d) {
            return moment(d).format('MMM Do');
        },
        monthYearDate(d) {
            return moment(d).format('MMM YYYY');
        },
        formatDateWithDay(d) {
            return moment(d).format('ddd, MMM Do');
        },
        relativeDate(d) {
            return moment(d).fromNow()
        },
        titleCase(string) {
            var sentence = string.toLowerCase().split(" ");
            for (var i = 0; i < sentence.length; i++) {
                sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
            }
            return sentence.join(' ');
        },
        showError(msg, details) {
            if (msg?.code === 'ABORTED') {
                return
            }

            if (typeof details == 'undefined') {
                details = null
            }
            EventBus.$emit('showError', { 'text': msg, 'details': details })
        },
        showWarning(msg) {
            EventBus.$emit('showWarning', msg)
        },
        showInfo(msg) {
            EventBus.$emit('showInfo', msg)
        },
        showSuccess(msg) {
            EventBus.$emit('showSuccess', msg)
        },
        dispatchJob(job) {
            EventBus.$emit('dispatchJob', job)
        },
        getAgentIcon: function(level) {
            switch (level) {
                case 'Agency Owner':
                    return 'fas fa-store'
                    break;
                case 'Agency Director':
                    return 'fas fa-store'
                    break;
                case 'Regional Agency Director':
                    return 'fas fa-store-alt'
                    break;
                case 'Managing Vice President':
                    return 'far fa-building'
                    break;
                case 'Senior Vice President':
                    return 'fas fa-building'
                    break;
                case 'Executive Vice President':
                    return 'fas fa-building'
                    break;
                case 'Associate Partner':
                    return 'fas fa-city'
                    break;
                case 'Senior Partner':
                    return 'fas fa-city'
                    break;
                case 'Managing Partner':
                    return 'fas fa-city'
                    break;
                default:
                    return 'far fa-user'
            }
        },
        indexByKey(id, data, key) {
            if (typeof key == 'undefined') {
                key = 'id';
            }
            for (var i = 0; i < data.length; i++) {
                if (data[i][key] == id) {
                    return i;
                }
            }
            return -1;
        },
        updateConfig(key, value, type) {
            return QuilityAPI.updateConfig(key, value, type)
        },
        updateAppConfig(key, value, type) {
            return QuilityAPI.updateAppConfig(key, value, type, this.user.Agent.AgentID)
        },
        getConfig(key) {
            if (typeof this.user.config[key] == 'undefined') {
                return null;
            }
            return this.user.config[key].ConfigValue;
        },
        getBusinessWeeks(date) {
            // build moment object
            // that represents the
            // given date
            const day = moment.utc(date)

            // determine the day of week
            // and use it to get the
            // values needed to determine
            // the begin and end dates
            const dayOfWeek = day.format("dddd")
            const saturdayDiff = weekdiff[dayOfWeek].saturday
			const beginDate = day.clone().subtract(saturdayDiff, 'days')
			const endDate = beginDate.clone().add(6, 'days')
            return {
                beginDate: beginDate.format("YYYY-MM-DD"),
                endDateFormat: endDate.format("YYYY-MM-DD")
            }
        },
        getBeginEndBusinessWeeks(begindate, enddate) {

            const begindateData = this.getBusinessWeeks(begindate)
            const enddateData = this.getBusinessWeeks(enddate)

            return {
                'beginDateRange': {
                    'begindate': begindateData.beginDate,
                    'enddate': begindateData.endDateFormat
                },
                'endDateRange': {
                    'begindate': enddateData.beginDate,
                    'enddate': enddateData.endDateFormat
                }
            }
        },
        getBusinessMonths(date) {
            // build moment object
            // that represents the
            // given date
            const day = moment.utc(date)

            // determine the day of week
            // and use it to get the
            // values needed to determine
            // the begin and end dates

            let endDateFormat
            const curmonth = day.month()

            const firstofmonth = day.clone().startOf('month').format("dddd")
            const endofmonth = day.clone().endOf('month').format("dddd")

            const firstDiff = weekdiff[firstofmonth].saturday
            const endDiff = weekdiff[endofmonth].friday

            // determine if the calculated end
            // is in the next month. if so then
            // then return that end date minus 7 days

            if (day.clone().endOf('month').subtract(endDiff, 'days').month() != curmonth) {
                endDateFormat = day.clone().endOf('month').subtract(endDiff, 'days').subtract(7, 'days').format("YYYY-MM-DD")
            } else {
                endDateFormat = day.clone().endOf('month').subtract(endDiff, 'days').format("YYYY-MM-DD")
            }

            return {
                'beginDate': day.clone().startOf('month').subtract(firstDiff, 'days').format("YYYY-MM-DD"),
                'endDateFormat': endDateFormat
            }
        },
        getBeginEndBusinessMonths(begindate, enddate) {

            const begindateData = this.getBusinessMonths(begindate)
            const enddateData = this.getBusinessMonths(enddate)

            return {
                'beginDateRange': {
                    'begindate': begindateData.beginDate,
                    'enddate': begindateData.endDateFormat
                },
                'endDateRange': {
                    'begindate': enddateData.beginDate,
                    'enddate': enddateData.endDateFormat
                }
            }
        },
        getBusinessYears(date) {
            let endDateFormat
            const day = moment.utc(date)

            const curyear = day.year()

            const firstofyear = day.clone().startOf('year').format("dddd")
            const endofyear = day.clone().endOf('year').format("dddd")

            const firstDiff = weekdiff[firstofyear].saturday
            const endDiff = weekdiff[endofyear].friday

            const endofyearM = moment.utc(`${curyear}-12-31`)

            if (endofyearM.day() === 5) {
                // last day of the year is a friday

                endDateFormat = endofyearM.format("YYYY-MM-DD")
            } else if (day.clone().endOf('year').add(endDiff, 'days').year() != curyear) {
                // the friday of the last week of the year
                // is in the next year

                endDateFormat = day.clone().endOf('year').add(endDiff, 'days').subtract(7, 'days').format("YYYY-MM-DD")
            } else {
                // the friday of the last week of the year
                // is in the this year

                endDateFormat = day.clone().endOf('year').add(endDiff, 'days').format("YYYY-MM-DD")
            }

            return {
                'beginDate': day.clone().startOf('year').add(firstDiff, 'days').format("YYYY-MM-DD"),
                'endDateFormat': endDateFormat
            }
        },
        getBeginEndBusinessYears(begindate, enddate) {

            const begindateData = this.getBusinessYears(begindate)
            const enddateData = this.getBusinessYears(enddate)

            return {
                'beginDateRange': {
                    'begindate': begindateData.beginDate,
                    'enddate': begindateData.endDateFormat
                },
                'endDateRange': {
                    'begindate': enddateData.beginDate,
                    'enddate': enddateData.endDateFormat
                }
            }
        },
        logActivity(action, params) {
            QuilityAPI.logActivty(action, this.$route.name, this.$route.path, params)
        },
        logTabActivty(tab) {
            QuilityAPI.logActivty('TabClick', tab, this.$route.path, {})
        },
        getAvailableBusinessDates(stat_period, allowNextWeek, allowCurrent, earliestDate = null) {

            //allow for a later starting point other than 52 weeks or 18 months ago
            if (earliestDate == null)
                earliestDate = new Date("2000-01-01")
            else
                earliestDate = new Date(earliestDate)


            if (stat_period == 'weeks' || stat_period == 'week') {
                var weeks = [];
                var current = moment()
                var day_of_the_week = moment().format('dddd');
                if (allowNextWeek) {
                    weeks.push({
                        label: 'Next Week',
                        endDateFormat: null, //moment(current).format('YYYY-MM-DD'),
                        startDateFormat: null, //moment(current).subtract(weekdiff[day_of_the_week]['saturday'], 'days').format('YYYY-MM-DD'),
                        period: 'week'
                    })
                    var current = moment().add(1, "week");
                }
                if (allowCurrent) {
                    weeks.push({
                        label: 'Current Week',
                        endDateFormat: null, //moment(current).format('YYYY-MM-DD'),
                        startDateFormat: null, //moment(current).subtract(weekdiff[day_of_the_week]['saturday'], 'days').format('YYYY-MM-DD'),
                        period: 'week'
                    })
                }
                //start at last week
                current = moment(current).subtract(weekdiff[day_of_the_week]['friday'], 'days')
                for (var i = 0; i < 52; i++) {
                    if (moment(current).subtract(6, 'days') > earliestDate) {
                        weeks.push({
                            label: moment(current).subtract(6, 'days').format('ddd MMM Do') + " - " + moment(current).format('ddd MMM Do'),
                            endDateFormat: moment(current).format('YYYY-MM-DD'),
                            startDateFormat: moment(current).subtract(6, 'days').format('YYYY-MM-DD'),
                            period: 'week'
                        })
                    }
                    current.subtract(7, 'days')
                }
                return weeks;
            } else {
                var months = [];
                var current = moment();

                // If the last Friday of the month is less than today go to the first day of the next month
                // This prevents returning the previous period that doesn't include the current date
                let last_friday_of_month = moment().endOf('month');
                while (last_friday_of_month.day() !== 5) {
                    last_friday_of_month.subtract(1, 'days');
                }
                if (last_friday_of_month < current) {
                    current.add(1, 'month').date(1);
                }

                var day_of_the_week = moment(current).startOf('month').format('dddd');
                var first_day_of_business_month = moment(current).startOf('month').subtract(weekdiff[day_of_the_week]['saturday'], 'days');
                if (allowCurrent) {
                    months.push({
                        label: 'Current Month',
                        endDateFormat: null, //moment(current).format('YYYY-MM-DD'),
                        startDateFormat: null, //first_day_of_business_month.format('YYYY-MM-DD'),
                        period: 'month'
                    })
                }
                current = current.subtract(1, 'months')
                day_of_the_week = moment(current).startOf('month').format('dddd');
                var endDateFormat = first_day_of_business_month.subtract(1, "days").format('YYYY-MM-DD');
                first_day_of_business_month = moment(current).startOf('month').subtract(weekdiff[day_of_the_week]['saturday'], 'days');
                for (var i = 0; i < 18; i++) {
                    if (first_day_of_business_month > earliestDate) {
                        months.push({
                            label: moment(current).format("MMM 'YY"),
                            endDateFormat: endDateFormat,
                            startDateFormat: first_day_of_business_month.format('YYYY-MM-DD'),
                            period: 'month'
                        })
                    }
                    endDateFormat = first_day_of_business_month.subtract(1, "days").format('YYYY-MM-DD');
                    current = current.subtract(1, 'months')
                    var day_of_the_week = moment(current).startOf('month').format('dddd');
                    first_day_of_business_month = moment(current).startOf('month').subtract(weekdiff[day_of_the_week]['saturday'], 'days');
                }
                return months;
            }
        },
        getAvailableCalendarDates(stat_period, allowNextWeek, allowCurrent, earliestDateString = null) {
			const earliestDate = earliestDateString === null
				? moment('2000-01-01', 'YYYY-MM-DD')
				: moment(earliestDateString, 'YYYY-MM-DD')
			if (stat_period === 'weeks' || stat_period === 'week') {
				const weeks = []
				if (allowNextWeek) {
					weeks.push({
						label: 'Next Week',
						endDateFormat: null,
						startDateFormat: null,
						period: 'week',
					})
				}
				if (allowCurrent) {
					weeks.push({
						label: 'Current Week',
						endDateFormat: null,
						startDateFormat: null,
						period: 'week',
					})
				}
				let current = moment().subtract(1, 'weeks')
				for (let i = 0; i < 52; i+= 1) {
					if (moment(current).startOf('week') < earliestDate) { break }
					const startDate = moment(current).startOf('week')
					const endDate = moment(current).endOf('week')
					weeks.push({
						label: startDate.format('ddd MMM Do') + ' - ' + endDate.format('ddd MMM Do'),
						startDateFormat: startDate.format('YYYY-MM-DD'),
						endDateFormat: endDate.format('YYYY-MM-DD'),
						period: 'week',
					})
					current.subtract(1, 'weeks')
				}
				return weeks
			} else {
				const months = []
				if (allowCurrent) {
					months.push({
						label: 'Current Month',
						startDateFormat: null,
						endDateFormat: null,
						period: 'month',
					})
				}
				let current = moment().subtract(1, 'months')
				for (let i = 0; i < 18; i+= 1) {
					if (moment(current).startOf('month') < earliestDate) { break }
					months.push({
						label: current.format("MMM 'YY"),
						startDateFormat: moment(current).startOf('month').format('YYYY-MM-DD'),
						endDateFormat: moment(current).endOf('month').format('YYYY-MM-DD'),
						period: 'month',
					})
					current = current.subtract(1, 'months')
				}
				return months
			}
		},
        setPageTitle(title) {
            if (!title) {
                document.title = 'Quility';
            } else {
                document.title = `${title} || Quility`
            }
        },
        getColor(color) {
            const vuetifyColor = this.$vuetify.theme.currentTheme[color]

            return vuetifyColor ? vuetifyColor : color
        },
        defaultCmsColumnSubBlocks(text) {
            if (!text)
                text = "Sed nec diam non eros lobortis tempor et eget sapien. Sed rutrum orci eget ligula mattis, vel vestibulum ligula feugiat. Cras et velit ut ipsum fringilla vulputate."
            var new_id = Math.random()
            return [{
                type: "full-width-text",
                text: text,
                id: new_id,
                type: 'full-width-text',
                hasFocus: false,
                styles: [],
                permissions: { role: null, permission: null, division: null },
                CarrierID: null,
                CarrierIDs: null,
                key: new_id
            }]
        },
        blockClasses(block, other) {
            if (typeof block != 'object') {
                return other;
            }
            if (typeof block.styles != 'object') {
                this.$set(block, 'styles', [])
            }
            var classes = [
                ...block.styles,
                block.type,
                other
            ]
            return classes.join(' ')
        },
        showBlock(block) {
            if (block.status === 'draft')
                return false

            if (block.startDateFormat !== null && block.startDateFormat !== undefined && block.startDateFormat !== "") {
                if (!moment(block.startDateFormat).isSameOrBefore(moment(), 'day'))
                    return false
            }

            if (block.endDateFormat !== null && block.endDateFormat !== undefined && block.endDateFormat !== "") {
                if (!moment(block.endDateFormat).isSameOrAfter(moment(), 'day'))
                    return false
            }

            let permissionsAreSetFor = function(obj) {
                if (!obj)
                    return false
                if (typeof obj == 'string' && obj.length > 0)
                    return true
                if (typeof obj != 'string' && obj.length > 0)
                    return true
                return false
            }

            //check divisions
            if (permissionsAreSetFor(block.permissions?.division)) {
                if (block.permissions.exclude == 'exclude') {
                    if (block.permissions.division == 'All' || this.hasDivision(block.permissions.division)) {
                        return false
                    }
                } else if (!this.hasDivision(block.permissions.division) && block.permissions.division != 'All') {
                    return false
                }
            }

            //check roles
            if (permissionsAreSetFor(block.permissions?.role)) {
                if (block.permissions.exclude == 'exclude') {
                    if (block.permissions.role == 'Any' || this.hasRole(block.permissions.role)) {
                        return false
                    }
                } else if (!this.hasRole(block.permissions.role) && block.permissions.role != 'Any') {
                    return false
                }
            }

            //check permission
            if (permissionsAreSetFor(block.permissions?.permission)) {
                if (block.permissions.exclude == 'exclude') {
                    if (block.permissions.permission == 'Any' || this.hasPermission(block.permissions.permission)) {
                        return false
                    }
                } else if (!this.hasPermission(block.permissions.permission) && block.permissions.permission != 'Any') {
                    return false
                }
            }

			//check contract level
			if (permissionsAreSetFor(block.permissions?.contractLevel)) {
				if (block.permissions.exclude == 'exclude') {
					if (block.permissions.contractLevel == 'Any' || block.permissions.contractLevel === this.user.Agent.ContractLevel) {
						return false
					}
				} else if (!block.permissions.contractLevel === this.user.Agent.ContractLevel && block.permissions.permission != 'Any') {
					return false
				}
			}

            //passed all checks
            return true
        },
        slingshotBadgeUrl(badge) {
            if(badge == 'regular')
                return process.env.VUE_APP_SLINGSHOT_BADGE_REGULAR
            if(badge == 'golden')
                return process.env.VUE_APP_SLINGSHOT_BADGE_GOLDEN
        }
    },
    computed: {
        role: function() {
            if (this.$auth.isAuthenticated) {
                if (typeof this.$auth.auth_user["http://quility.com/roles"] == "undefined") {
                    return null
                }
                return this.$auth.auth_user["http://quility.com/roles"][0];
            }
            return null;
        },
        roles: function() {
            if (this.$auth.user) {
                return this.$auth.auth_user["http://quility.com/roles"];
            }
            return [];
        },
        'role_choices': function() {
            return [
                'UnlicensedAgent',
                'SalesRep',
                'AgencyOwner',
                'Staff',
                'Exec',
                'SuperAdmin'
            ]
        },
        'permission_choices': function() {
            return Object.keys(this.user.admin.AvailablePermissions).map(f => this.user.admin.AvailablePermissions[f].value)
            /* old hard coded way
            return [
                'manage:Carriers',
                'manage:Content',
                'manage:Staff',
                'manage:Agents',
                'manage:AgentLevel',
                'manage:AgentUpline',
                'read:Jobs',
                'use:FIForm',
                'qms:commission',
                'manage:LeadOrders',
                'manage:Carriers',
            ]
            */
        },
        divisions: function() {
            if (this.user.currentSetDivision != null) {
                return [this.user.currentSetDivision];
            }
            if (this.user.permissions.length > 0) {
                var d = [];
                for (var i = 0; i < this.user.permissions.length; i++) {
                    if (this.user.permissions[i].indexOf("division:") > -1) {
                        d.push(this.user.permissions[i].replace('division:', ''))
                    }
                }
                return d;
            }
            return [];
        },
        raw_divisions: function() {
            if (this.user.permissions.length > 0) {
                var d = [];
                for (var i = 0; i < this.user.permissions.length; i++) {
                    if (this.user.permissions[i].indexOf("division:") > -1) {
                        d.push(this.user.permissions[i].replace('division:', ''))
                    }
                }
                return d;
            }
            return [];
        },
        user_meta: function() {
            if (this.$auth.isAuthenticated && this.$auth.auth_user["http://quility.com/meta_data"]) {
                return this.$auth.auth_user["http://quility.com/meta_data"];
            }
            return {};
        },
        user() {
            return User.query().first();
        },
        form_rules() {
            return {
                'required': (v) => {
					if (Array.isArray(v)) {
						return !!v.length || 'Required!'
					}
					return !!v || 'Required!'
				},
                'date': function(v) {
                    if (typeof v == 'undefined' || v == null || v == '') {
                        return true
                    }
                    if (moment(v, 'YYYY-MM-DD', true).isValid()) {
                        return true;
                    }
                    return 'Enter a valid date';
                },
                'new_agent_start_date': function(startDateVal, beforeDateValFrom, beforeDateValTo) {
                    let startDateFormat = moment(startDateVal, "YYYY-MM-DD");
                    let beforeDateValFromFormat = moment(beforeDateValFrom, "YYYY-MM-DD")
                    let beforeDateValToFormat = moment(beforeDateValTo, "YYYY-MM-DD")
                    let msg = ''
                    if (startDateFormat.isBetween(beforeDateValFromFormat, beforeDateValToFormat)){
                        msg = `Invalid starting date. It can't be between previous Level date range`
                        return msg
                    } else if (startDateFormat.isBefore(beforeDateValToFormat)){
                        msg = `Invalid new starting date. Need to be after previous Level starting and ending date`
                        return msg
                    } else {
                        return true;
                    }
                    
                },
                'edit_agent_start_date': function(startDateVal, endDateVal, beforeDateValFrom, beforeDateValTo, afterDateValFrom) {
                    let startDateFormat = moment(startDateVal, "YYYY-MM-DD")
                    let beforeDateValFromFormat = moment(beforeDateValFrom, "YYYY-MM-DD")
                    let beforeDateValToFormat = moment(beforeDateValTo, "YYYY-MM-DD")
                    let afterDateValFromFormat = moment(afterDateValFrom, "YYYY-MM-DD")
                    let endDateValFormat = moment(endDateVal, "YYYY-MM-DD")
                    let msg = ''
                    if (startDateFormat.isBetween(beforeDateValFromFormat, beforeDateValToFormat)){
                        msg = `Invalid starting date. It can't be between previous Level date range`
                        return msg
                    } else if (startDateFormat.isBefore(beforeDateValFromFormat)) {
                        msg = `Invalid starting date. Need to be after previous Level starting and ending date`
                        return msg
                    } else if (startDateFormat.isAfter(afterDateValFromFormat) && afterDateValFromFormat !== null) {
                        msg = `Invalid starting date. Need to be before next Level starting date`
                        return msg
                    } else if (startDateFormat.isAfter(endDateValFormat)) {
                        msg = `Starting date can't be greater than ending date`
                        return msg
                    } else {
                        return true
                    }
                    
                },
                'edit_agent_end_date': function(startDateVal, endDateVal, afterDateValFrom, afterDateValTo) {
                    let endDateFormat = moment(endDateVal, "YYYY-MM-DD");
                    let afterDateValFromFormat = moment(afterDateValFrom, "YYYY-MM-DD")
                    let afterDateValToFormat = moment(afterDateValTo, "YYYY-MM-DD")
                    let startDateValFormat = moment(startDateVal, "YYYY-MM-DD")
                    let msg = ''
                    if (endDateFormat.isBetween(afterDateValFromFormat, afterDateValToFormat)){
                        msg = 'Invalid ending date. Is between next Level date range'
                        return msg
                    } else if (endDateFormat.isAfter(afterDateValFromFormat)) {
                        msg = 'Invalid ending date. Need to be before next Level starting date'
                        return msg
                    } else if (endDateFormat.isBefore(startDateValFormat)) {
                        msg = `Ending date can't be lower than starting date`
                        return msg
                    } else {
                        return true
                    }
                },
                'time': function(v) {
                    if (typeof v == 'undefined' || v == null || v == '') {
                        return true
                    }
                    if (moment('2020/1/1 ' + v).isValid()) {
                        return true;
                    }
                    return 'Enter a valid time';
                },
                'number': function(v) {
                    if (typeof v == 'undefined' || v == null || v == '') {
                        return true
                    }
                    v = Number(v);
                    if (typeof v === 'number' && isFinite(v)) {
                        return true;
                    }
                    return 'Enter a number';
                },
                'currency': function(v) {
                    if (typeof v == 'undefined' || v == null || v == '') {
                        return true
                    }
                    v = typeof v === 'string' ? v.replaceAll(',', '') : v
                    v = Number(v);
                    if (typeof v === 'number' && isFinite(v)) {
                        return true;
                    }
                    return 'Enter a number';
                },
                'int': function(v) {
                    if (typeof v == 'undefined' || v == null || v == '') {
                        return true
                    }
                    if (Number.isInteger(v)) {
                        return true;
                    }
                    return 'Enter a whole number';
                },
                'email': v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
                'zip': v => /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) || 'Enter a valid zipcode', // explnation of this regex can be found here https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s14.html
                'phone': function(v) {
                    if (typeof v == 'string') {
                        v = v.replaceAll("-", "")
                        if (v != null && (Number.isInteger(Number(v)) && v.length >= 10)) {
                            return true
                        }
                    }
                    return 'Please enter a valid phone number in the phone field.'
                },
                'not_upper_case': function(v) {
                    if (typeof v == 'undefined') {
                        return true
                    }
                    if (v == null || v.length <= 2) {
                        return true;
                    }
                    if (v !== v.toUpperCase()) {
                        return true;
                    }
                    return 'Please do not use all upper case.';
                },
                'no_special_characters': function(v) {
                    if (v == null || typeof v == 'undefined') {
                        return true
                    }

                    // make sure we're working with a string
                    const str = v.toString()
                    const matches = str.match(/([a-z]|[A-Z]|[']|[-]|[\s])/g) || []

                    if (str.length == matches.length) {
                        return true
                    }

                    return 'Please do not add any special characters.'
                },
                'valid_name_characters': function(v) {
                    if (v == null || typeof v == 'undefined') {
                        return true
                    }

                    // make sure we're working with a string
                    const str = v.toString()
                    const matches = str.match(/([a-z]|[A-Z]|[']|[-]|[\s]|[&])/g) || []

                    if (str.length == matches.length) {
                        return true
                    }

                    return 'Please do not add any special characters.'
                },
                'json': function(v) {
                    try {
                        JSON.parse(v);
                    } catch (e) {
                        return 'Please enter valid JSON';
                    }
                    return true;
                }
            }
        },
        form_rules_mapped() {
            var r = [];
            var g = this
            Object.keys(this.form_rules).forEach(function(key) {
                r.push({
                    text: key,
                })
            })
            return r;
        },
        isDirectToSymmetry() {
            return this.user.DirectAgencyOwner.AgentCode == 'SFG0000001'
        },
        isAgent() {
            return this.hasRole(['AgencyOwner', 'SalesRep']);
        },
        isImpersonating() {
            if (typeof this.user_meta.RevertAgentCode == 'undefined') {
                return false;
            }
            return this.user_meta.RevertAgentCode !== null;
        },
        unlicensedAgentMode() {
            return this.isImpersonating && this.user?.AgentCode === UNLICENSED_AGENT_CODE
        },
        teamLeaderAgentMode() {
            return this.isImpersonating && this.user?.AgentCode === TEAM_LEADER_AGENT_CODE
        },
        sfgDirectAgentMode() {
            return this.isImpersonating && this.user?.AgentCode === SFG_DIRECT_AGENT_CODE
        },
        isDemoMode() {
            return this.unlicensedAgentMode || this.teamLeaderAgentMode || this.sfgDirectAgentMode
        },

    }
}